import { Card, Col, Input, List, Row, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ALL_CONFIGURATION } from './graphql/Queries'
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import client from 'apollo'
import toast from 'utils/toast'
import { CREATE_CONFIGURATION, DELETE_CONFIGURATION } from './graphql/Mutations'
// import moment from 'moment'
const moment = require('moment-timezone')

const ConfigurationFollowUp = () => {
  const [showAddField, setShowAddField] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [daysAfter, setDaysAfter] = useState(0)
  const [daysAfterDate, setDaysAfterDate] = useState('')
  const [currentCardId, setCurrentCardId] = useState(null)

  const handleAddClick = (cardId) => {
    setShowAddField(true)
    setCurrentCardId(cardId)
  }

  const handleDoneCreate = () => {
    client
      .mutate({
        mutation: CREATE_CONFIGURATION,
        variables: {
          input: {
            title,
            description,
            daysAfterDate: moment(daysAfterDate).format(),
            daysAfter,
            cardId: currentCardId,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_CONFIGURATION,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setShowAddField(!true)
        toast('success', 'Configuration Added')
      })
      .catch((err) => {
        toast('error', 'Something want wrong')
        console.log({ err })
        console.log(err)
      })
  }

  const handleDelete = (id) => {
    client
      .mutate({
        mutation: DELETE_CONFIGURATION,
        variables: {
          input: {
            id,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_CONFIGURATION,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        toast('success', 'Configuration deleted')
      })
      .catch((err) => console.log(err))
  }

  const { data } = useQuery(GET_ALL_CONFIGURATION, {
    fetchPolicy: 'network-only',
  })
  return (
    <>
      <div>
        <Card
          title={<div className='add_top_btn'>Standard Follow Up</div>}
          className='flex-parent-element follow_ups_card'
          bordered={false}
          headStyle={{ border: 'none' }}
        >
          <Col gutter={24}>
            {data &&
              data?.getAllConfiguration?.map((card) => {
                return (
                  <Col span={12}>
                    <List
                      title={card.title}
                      style={{
                        width: '200%',
                      }}
                    >
                      <List.Item>
                        <p>{card.description} </p>
                        {/* <p>{card.cardId}</p> */}
                        <p>Days After: {card.daysAfter}</p>
                        <Tooltip title='Delete Follow Up' color={'#afcfff'}>
                          <MinusCircleOutlined
                            onClick={() => handleDelete(card.id)}
                          />
                        </Tooltip>
                      </List.Item>
                    </List>
                  </Col>
                )
              })}
          </Col>
          {showAddField ? (
            <div>
              <Row gutter={16}>
                <Col span={6}>
                  <Input
                    name='title'
                    type='text'
                    placeholder='Enter title'
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Col>
                <Col span={6}>
                  <Input
                    name='description'
                    type='text'
                    placeholder='description'
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
                <Col span={5}>
                  <Input
                    name='daysAfter'
                    type='number'
                    placeholder='Enter days after'
                    onChange={(e) => setDaysAfter(e.target.value)}
                  />
                </Col>
                <Col span={6}>
                  <Input
                    name='daysAfterDate'
                    type='date'
                    placeholder='Enter days after date'
                    onChange={(e) => setDaysAfterDate(e.target.value)}
                  />
                </Col>
                <Col
                  span={1}
                  style={{ display: 'flex ', alignItems: 'center' }}
                >
                  <Tooltip title='Save Follow Up' color={'#afcfff'}>
                    <CheckCircleOutlined
                      style={{ fontSize: '18px' }}
                      onClick={handleDoneCreate}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </div>
          ) : null}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <PlusCircleOutlined
              onClick={() => handleAddClick()}
              style={{
                fontSize: '26px',
                color: 'blue',
                marginTop: '10px',
                // marginLeft: '700px',
              }}
            />
          </div>
        </Card>
      </div>
    </>
  )
}

export default ConfigurationFollowUp
