import { AppContext } from 'AppContext'
import client from 'apollo'
import Dashboard from 'modules/Auth/Layout'
import { GET_CURRENT_USER } from 'modules/Auth/graphql/Queries'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ProtectedRoutes = () => {
  const navigate = useNavigate()
  const { dispatch } = useContext(AppContext)

  const auth = localStorage.getItem('token')
  useEffect(() => {
    if (auth) {
      client
        .query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
        .then((res) => {
          dispatch({ type: 'GET_CURRENT_USER', data: res.data.currentUser })
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      navigate('/')
    }
  }, [auth, dispatch, navigate])

  return <>{<Dashboard />}</>
}

export default ProtectedRoutes
