import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { Menu } from 'antd'

export default function SideBarMenu({ setVisibleDrawer = () => {} }) {
  const params = useParams()
  return (
    <Menu
      theme='dark'
      mode='inline'
      selectedKeys={[params[Object.keys(params)[0]] || 'dashboard']}
    >
      <Menu.Item key='dashboard' onClick={() => setVisibleDrawer(false)}>
        <NavLink to='/' className='nav-text'>
          <span className='sidebar-text'>Dashboard</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='organizations' onClick={() => setVisibleDrawer(false)}>
        <NavLink to='/organizations' className='nav-text'>
          <span className='sidebar-text'>Organizations</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='configurations' onClick={() => setVisibleDrawer(false)}>
        <NavLink to='/configurations' className='nav-text'>
          <span className='sidebar-text'>Configurations</span>
        </NavLink>
      </Menu.Item>
    </Menu>
  )
}
