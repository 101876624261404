import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import archiveIcon from '../../assets/images/archive-icon.png'
import DeleteIcon from '../../assets/images/delete-icon.png'
import BellIcon from '../../assets/images/bell-icon.png'
import MessageIcon from '../../assets/images/message-icon.png'
import completedIcon from '../../assets/images/completed-icon.png'
import deleteIcon from '../../assets/images/delete-icon-reminder.png'

import './index.css'
import client from 'apollo'
import {
  CREATE_COMMENT,
  CREATE_CONFIGURATION,
  DELETE_CARD,
  DELETE_CONFIGURATION,
  UPDATE_CARD,
} from 'componant/graphql/Mutations'
import moment from 'moment'
import {
  GET_ALL_CARDS,
  GET_ALL_DASHBOARD_CARDS,
  GET_CARDS,
} from 'modules/Dashboard/graphql/Queries'
import { useQuery } from '@apollo/client'
import {
  GET_ALL_ACTIVITY,
  GET_ALL_COMMENTS,
  GET_CONFIGURATION,
  GET_ORGANIZATION_USERS,
} from 'componant/graphql/Queries'
import toast from 'utils/toast'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { AppContext } from 'AppContext'

const { Option } = Select

const CreateModel = ({ openModel, cardType = {} }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext)
  const [assignee, setAssignee] = useState(cardType?.data?.assignee)
  const [values, setValues] = useState({
    title: cardType?.data?.title || '',
    description: cardType?.data?.description || '',
    status: cardType?.data?.status,
    opportunity: cardType?.data?.opportunity || 0,
    assignee: assignee,
  })

  const [reminderState, setReminderState] = useState({
    title: '',
    description: '',
    daysAfter: '',
    daysAfterDate: '',
  })

  const [status, setStatus] = useState('')
  const [assignUser, setAssignUser] = useState([])
  const [comment, setComment] = useState('')
  const [addReminder, setAddReminder] = useState(false)

  const { data: allCommentData } = useQuery(
    GET_ALL_COMMENTS,
    { variables: { input: { cardId: cardType?.data?.id } } },
    { fetchPolicy: 'network-only' }
  )
  const { data: allActivityData } = useQuery(
    GET_ALL_ACTIVITY,
    { variables: { input: { cardId: cardType?.data?.id } } },
    { fetchPolicy: 'network-only' }
  )
  const { data: getConfiguration } = useQuery(
    GET_CONFIGURATION,
    {
      variables: {
        input: {
          cardId: cardType?.data?.id?.toString(),
        },
      },
    },
    {
      fetchPolicy: 'network-only',
    }
  )
  const { data: allOrganizationUser, loading: organizationUserLoading } =
    useQuery(GET_ORGANIZATION_USERS, {
      fetchPolicy: 'network-only',
    })

  const commentArray = allCommentData?.getAllCommentsOnCard
  const activityArray = allActivityData?.getAllActivities
  const reminderArray = getConfiguration?.getAllConfiguration

  useEffect(() => {
    setAssignUser(
      allOrganizationUser?.getOrganizationUsers?.filter(
        (item) => +item?.id === +assignee
      )
    )
  }, [allOrganizationUser?.getOrganizationUsers, assignee])

  const handleChange = (event) => {
    const { name, value } = event.target
    setValues((oldData) => {
      return {
        ...oldData,
        [name]: value,
      }
    })
  }

  const handleUpdate = () => {
    client
      .mutate({
        mutation: UPDATE_CARD,
        variables: {
          input: {
            ...values,
            assignee: assignee,
            opportunity: parseFloat(values?.opportunity),
            status: status ? status : values?.status,
          },
          where: {
            id: cardType?.data?.id,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_CARDS && GET_CARDS,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openModel()
        toast('success', 'Card successfully updated')
      })
      .catch((err) => console.log(err))
  }
  const handleDelete = () => {
    client
      .mutate({
        mutation: DELETE_CARD,
        variables: { input: { id: parseInt(cardType?.data?.id) } },
        refetchQueries: [
          {
            query: GET_ALL_DASHBOARD_CARDS,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openModel()
        toast('success', 'Card successfully deleted')
      })
      .catch((err) => console.log(err))
  }

  const handleDoneCreate = () => {
    client
      .mutate({
        mutation: CREATE_CONFIGURATION,
        variables: {
          input: {
            ...reminderState,
            daysAfter: +reminderState.daysAfter,
            cardId: JSON.stringify(cardType?.data?.id),
          },
        },
        refetchQueries: [
          {
            query: GET_CONFIGURATION,
            GET_ALL_ACTIVITY,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setAddReminder(!true)
      })
  }

  const handleDeleteReminder = (id) => {
    client
      .mutate({
        mutation: DELETE_CONFIGURATION,
        variables: { input: { id } },
        refetchQueries: [
          {
            query: GET_CONFIGURATION,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        toast('success', 'Reminder successfully deleted')
      })
      .catch((err) => console.log(err))
  }

  const handleCreateComment = () => {
    client
      .mutate({
        mutation: CREATE_COMMENT,
        variables: {
          input: { cardId: cardType?.data?.id?.toString(), comment },
        },
        refetchQueries: [
          {
            query: GET_ALL_COMMENTS,
            variables: { input: { cardId: cardType?.data?.id } },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        toast('success', 'Comment successfully created')
        setComment('')
      })
      .catch((err) => console.log(err))
  }

  const handleAssigneeSelect = (e) => {
    setAssignee(e)
    setValues((curr) => {
      return { ...curr, assignee: e }
    })
    // setOpenSelectUser(false)
  }

  const handleStatusSelect = (e) => {
    setStatus(e)
  }

  const handleReminderChange = (e) => {
    const { name, value } = e.target
    setReminderState((oldData) => {
      return {
        ...oldData,
        [name]: value,
      }
    })
  }

  const handleReminderDateChange = (e) => {
    setReminderState((oldData) => {
      return {
        ...oldData,
        daysAfterDate: moment(e).format(),
      }
    })
  }

  return (
    <Modal
      visible={true}
      onCancel={openModel}
      footer={null}
      width={1200}
      height={844}
      className='main_model'
    >
      {/* <div>card Code:{data?.getCardCode}</div> */}
      <div>
        <Row className='activity-row' gutter={16}>
          <Col span={12}>
            <div>
              <Input
                width={100}
                placeholder='Title'
                className='title-field'
                name='title'
                onChange={handleChange}
                value={values.title}
              />
            </div>
          </Col>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={8}>
                <Input
                  type='number'
                  style={{
                    width: '100%',
                    height: '55px',
                    border: '1px solid #afcfff',
                    borderRadius: '15px',
                    fontSize: '14px',
                  }}
                  name='opportunity'
                  value={values?.opportunity}
                  placeholder='$ amount in numbers'
                  onChange={handleChange}
                />
              </Col>
              <Col span={5}>
                <Select
                  className='status-btn'
                  placeholder='status'
                  defaultValue={values.status}
                  onChange={handleStatusSelect}
                  bordered={false}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <option label='Pending' value='Pending'>
                    Pending
                  </option>
                  <option label='Initiated' value='Initiated'>
                    Initiated
                  </option>
                  <option label='Closed' value='Closed'>
                    Closed
                  </option>
                </Select>
              </Col>
              <Col span={11}>
                {/* <div style={{ display: 'grid', justifyContent: 'center' }}>
                  <Select
                    allowClear
                    placeholder='assignee'
                    mode='multiple'
                    onChange={handleAssigneeSelect}
                    bordered={false}
                    className='assignee_btn'
                  >
                    {!organizationUserLoading &&
                      allOrganizationUser?.getOrganizationUsers?.map(
                        (option) => (
                          <Option
                            value={option.id}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {`${option.firstName} ${option.lastName}`}
                          </Option>
                        )
                      )}
                  </Select>
                  <div>
                    <Avatar.Group>
                      <Avatar
                        size='small'
                        icon={assignUser?.nickName}
                        style={{ background: assignUser?.nickNameColor }}
                      />
                    </Avatar.Group>
                    {!values.assignee.length <= 1 && values?.assignee && (
                      <span
                        style={{ textTransform: 'capitalize' }}
                      >{`${assignUser?.firstName} ${assignUser?.lastName}`}</span>
                    )}
                  </div>
                </div> */}
                <div
                  className={assignUser && 'remove_textBox'}
                  style={{ display: 'block' }}
                >
                  {/* {assignUser?.length !== 0 && (
                    <Avatar.Group>
                      {assignUser?.map((user) => {
                        return (
                          <Avatar
                            size='small'
                            icon={user?.nickName}
                            style={{ background: user?.nickNameColor }}
                          />
                        )
                      })}
                    </Avatar.Group>
                  )} */}
                  <div style={{ display: 'grid', marginLeft: '25px' }}>
                    <Select
                      allowClear
                      placeholder='assignee'
                      mode='multiple'
                      onChange={handleAssigneeSelect}
                      bordered={false}
                      value={values?.assignee}
                      className='assignee_btn'
                    >
                      {!organizationUserLoading &&
                        allOrganizationUser?.getOrganizationUsers?.map(
                          (option) => (
                            <Option
                              value={option.id}
                              style={{ textTransform: 'capitalize' }}
                            >
                              {`${option.firstName} ${option.lastName}`}
                            </Option>
                          )
                        )}
                    </Select>
                    <div style={{ display: 'flex' }}>
                      {allOrganizationUser?.getOrganizationUsers
                        ?.filter((user) => assignee?.includes(user.id))
                        .map((data) => {
                          return (
                            <Avatar.Group>
                              <Avatar
                                size='small'
                                icon={data?.nickName}
                                style={{ background: data?.nickNameColor }}
                              />
                            </Avatar.Group>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div>
              <TextArea
                className='text-area-field'
                rows={4}
                placeholder='Desc'
                name='description'
                onChange={handleChange}
                value={values.description}
              />
              {currentUser?.role === 'Admin' && (
                <div className='reminder-box'>
                  <div className='reminder-heading-text'>Reminders</div>
                  {addReminder && (
                    <div style={{ marginTop: '10px' }}>
                      <Row gutter={16}>
                        <Col span={6}>
                          <Input
                            name='title'
                            type='text'
                            placeholder='Enter title'
                            onChange={handleReminderChange}
                          />
                        </Col>
                        <Col span={6}>
                          <Input
                            name='description'
                            type='text'
                            placeholder='Description'
                            onChange={handleReminderChange}
                          />
                        </Col>
                        <Col span={5}>
                          <Input
                            name='daysAfter'
                            type='number'
                            placeholder='Days after'
                            onChange={handleReminderChange}
                          />
                        </Col>
                        <Col span={6}>
                          <DatePicker
                            name='daysAfterDate'
                            onChange={handleReminderDateChange}
                          />
                        </Col>
                        <Col
                          span={1}
                          style={{ display: 'flex ', alignItems: 'center' }}
                        >
                          <Tooltip title='Save Reminder' color={'#afcfff'}>
                            <CheckCircleOutlined
                              style={{ fontSize: '18px' }}
                              onClick={(e) => handleDoneCreate(e)}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <div>
                      <PlusCircleOutlined
                        onClick={() => setAddReminder(!addReminder)}
                        style={{
                          fontSize: '15px',
                          color: 'blue',
                        }}
                      />
                    </div>
                  </div>
                  {reminderArray
                    ?.filter(
                      (items) => items.cardId === cardType?.data?.id?.toString()
                    )
                    ?.map((item) => (
                      <div>
                        <div className='reminder-box-text'>
                          <div>{item?.title}</div>
                          <div>{item?.description}</div>
                          <div>{item?.daysAfter}</div>
                          <div>
                            {moment(item?.daysAfterDate).format('DD-MM-YYYY')}
                          </div>
                          <div className='reminder-image'>
                            <Image
                              src={BellIcon}
                              alt='BellIcon'
                              preview={false}
                            />
                            <Image
                              src={MessageIcon}
                              alt='MessageIcon'
                              preview={false}
                            />
                            <Image
                              src={completedIcon}
                              alt='completedIcon'
                              preview={false}
                            />
                            <Image
                              src={deleteIcon}
                              alt='deleteIcon'
                              preview={false}
                              onClick={() => handleDeleteReminder(item.id)}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </Col>
          <Col span={12}>
            <>
              <div className='activity-box'>
                <div className='activity-heading-text'>Activity</div>
                {activityArray?.map((items, index) => {
                  return (
                    <>
                      {(index === 0 ||
                        (index > 0 &&
                          moment(activityArray[index]?.createdAt).format(
                            'DD-MM-YYYY'
                          ) !==
                            moment(activityArray[index - 1]?.createdAt).format(
                              'DD-MM-YYYY'
                            ))) && (
                        <div className='date-text-line'>
                          Date : {moment(items?.createdAt).format('Do MMMM')}
                        </div>
                      )}
                      <div className='task-detail'>
                        <div className='activity_task_line'>
                          <Avatar
                            size='small'
                            icon={items?.title.split(' ')[0]}
                            style={{ background: items?.title.split(' ')[1] }}
                          />
                          &nbsp;&nbsp;&nbsp;{' '}
                          {`${items?.title.split(' ')[2]} ${
                            items?.title.split(' ')[3]
                          }`}{' '}
                          -- {items?.description}
                        </div>
                        <div className='activity_task_line'>
                          {moment(items?.createdAt).format('LT')}
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
              <div className='comments-box'>
                <div className='activity-heading-text'>Comments</div>
                <div className='comments-section'>
                  {commentArray?.map((items, index) => {
                    return (
                      <>
                        {(index === 0 ||
                          (index > 0 &&
                            moment(commentArray[index]?.createdAt).format(
                              'DD-MM-YYYY'
                            ) !==
                              moment(commentArray[index - 1]?.createdAt).format(
                                'DD-MM-YYYY'
                              ))) && (
                          <div
                            className='date-text-line'
                            style={{ textAlign: 'center' }}
                          >
                            {moment(items?.createdAt).format('Do MMMM')}
                          </div>
                        )}
                        <div className='task-detail'>
                          <div className='task-text-line'>
                            <Avatar
                              size='small'
                              icon={items?.comment?.split(' ')[0]}
                              style={{
                                background: items?.comment?.split(' ')[1],
                              }}
                            />
                            &nbsp;&nbsp;&nbsp;
                            {`${items?.comment?.split(' ')[2]} ${
                              items?.comment?.split(' ')[3]
                            }`}
                            &nbsp;&nbsp;&nbsp;
                            {`${items?.comment
                              ?.split(' ')
                              ?.slice(4)
                              ?.join(' ')}`}
                          </div>
                          <div className='task-text-line'>
                            {moment(items?.createdAt).format('LT')}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
                <Space direction='' style={{ marginTop: '20px' }}>
                  <div style={{ width: '400px' }}>
                    <Input
                      type='text'
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      className='text-area-field'
                      placeholder='Your Comments here'
                      rows={4}
                    />
                  </div>
                  <Button
                    onClick={handleCreateComment}
                    style={{
                      borderRadius: '40px',
                      border: '1px solid #afcfff',
                    }}
                  >
                    Submit
                  </Button>
                </Space>
              </div>
            </>
          </Col>
        </Row>

        <div className='bottom-icon-div'>
          <div className='images-div'>
            <Tooltip title='Save Changes' color={'#9896FF'}>
              <Image
                src={archiveIcon}
                alt='archiveIcon'
                preview={false}
                onClick={handleUpdate}
              />
            </Tooltip>
          </div>
          <Tooltip title='Delete Card' color={'#9896FF'}>
            <Image
              src={DeleteIcon}
              alt='DeleteIcon'
              onClick={handleDelete}
              preview={false}
            />
          </Tooltip>
        </div>
      </div>
    </Modal>
  )
}
export default CreateModel
