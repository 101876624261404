import { gql } from '@apollo/client'

export const GET_ALL_ACTIVITY = gql`
  query getAllActivities($input: activityInput) {
    getAllActivities(input: $input) {
      userId
      title
      description
      createdAt
      cardId
    }
  }
`

export const GET_ALL_REMINDERS = gql`
  query getAllReminders($input: getReminderInput) {
    getAllReminders(input: $input) {
      createdAt
      reminderTitle
      userId
      cardId
      card {
        title
      }
    }
  }
`

export const GET_ALL_COMMENTS = gql`
  query GetAllCommentsOnCard($input: getInput) {
    getAllCommentsOnCard(input: $input) {
      createdAt
      comment
    }
  }
`

export const GET_ORGANIZATION_USERS = gql`
  query getOrganizationUsers {
    getOrganizationUsers {
      lastName
      firstName
      email
      id
      role
      nickName
      nickNameColor
    }
  }
`

export const GET_CARD_CODE = gql`
  query getCardCode($input: cardCodeInput) {
    getCardCode(input: $input)
  }
`

export const GET_CONFIGURATION = gql`
  query Query {
    getAllConfiguration {
      id
      title
      description
      daysAfter
      daysAfterDate
      userId
      cardId
    }
  }
`
