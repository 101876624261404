import { Avatar, Col, Input, Layout, List, Row } from 'antd'
import { useContext, useState } from 'react'
import { AppContext } from 'AppContext'
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons'
import toast from 'utils/toast'
import { UPDATE_USER } from '../graphql/Mutations'
import client from 'apollo'
import { GET_CURRENT_USER } from '../graphql/Queries'

const { Content } = Layout
const Profile = () => {
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext)
  const [showAddField, setShowAddField] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleInput = () => {
    setShowAddField(true)
  }

  const handleCreate = () => {
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          input: {
            firstName,
            lastName,
          },
        },
      })
      .then((res) => {
        client
          .query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
          .then((res) => {
            dispatch({ type: 'GET_CURRENT_USER', data: res.data.currentUser })
          })
        setShowAddField(false)
        toast('success', 'Updated User')
      })
      .catch((err) => console.log(err))
  }

  return (
    <Layout
      className='site-layout'
      style={{
        minHeight: '120vh',
        backgroundColor: 'rgba(255, 255, 255,1  )',
      }}
    >
      <Content
        style={{
          margin: '30px',
          overflow: 'hidden',
        }}
      >
        <Row>
          <Col span={4}>
            <Avatar
              size={100}
              icon={currentUser?.nickName}
              style={{ background: currentUser?.nickNameColor }}
            />
          </Col>
          <Col span={6} style={{ display: 'grid', alignContent: 'center' }}>
            <span
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '25px',
              }}
            >
              Name
            </span>
            <span
              style={{
                textTransform: 'capitalize',
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '25px ',
              }}
            >
              {`${currentUser?.firstName} ${currentUser?.lastName}`}{' '}
              <EditOutlined onClick={handleInput} />
            </span>
            <span
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '25px',
              }}
            >
              {currentUser?.role} {currentUser?.organization?.name}
            </span>
          </Col>
        </Row>
        {showAddField ? (
          <div>
            <Row gutter={16}>
              <Col span={10}>
                <List>
                  <List.Item>
                    <Input
                      style={{ width: '500px' }}
                      name='firstName'
                      type='text'
                      placeholder='Enter First Name'
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </List.Item>
                </List>
              </Col>
              <Col span={10}>
                <List>
                  <List.Item>
                    <Input
                      style={{ width: '500px' }}
                      name='lastName'
                      type='text'
                      placeholder='Enter Last Name'
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </List.Item>
                </List>
              </Col>
              <Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
                <List>
                  <List.Item>
                    <CheckCircleOutlined
                      style={{ fontSize: '18px' }}
                      onClick={handleCreate}
                    />
                  </List.Item>
                </List>
              </Col>
            </Row>
          </div>
        ) : null}
      </Content>
    </Layout>
  )
}
export default Profile
