import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const Spinner = () => {
  return (
    <div>
      <LoadingOutlined style={{ fontSize: 40 }} />
    </div>
  )
}
export default Spinner
