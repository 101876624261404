import { gql } from '@apollo/client'

export const GET_ORGANIZATION = gql`
  query getAllOrganization {
    getAllOrganization {
      name
      id
    }
  }
`
