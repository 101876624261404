import { Button, Card, Col, Layout, Row } from 'antd'
import './index.css'
import Cards from 'componant/Cards/Cards'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'
import CreateModel from 'componant/pop-ups/CreateModel'
import { useQuery } from '@apollo/client'
import { GET_CARDS } from './graphql/Queries'
import { Link } from 'react-router-dom'
import moment from 'moment'
import CreateTaskModel from 'componant/pop-ups/CreateTaskModel'
import Loader from 'componant/Loader/Loader'
import Page404 from 'componant/Page404'

const { Content } = Layout

const Dashboard = ({
  id,
  title,
  status,
  description,
  opportunity,
  dueDate,
}) => {
  const [openCreateModel, setOpenCreateModel] = useState(undefined)
  const [cardType, setCardType] = useState({ type: '', show: '', data: {} })

  var dateIn = moment().format('YYYY-MM-DD')
  const [openCreateTaskModel, setOpenCreateTaskModel] = useState(undefined)
  const [taskTitle, setTaskTitle] = useState('')

  const { data, loading, error } = useQuery(GET_CARDS, {
    fetchPolicy: 'network-only',
  })

  const openModel = (e, type) => {
    setOpenCreateModel(!openCreateModel)
  }

  const openTaskModel = (data, type) => {
    setCardType({
      type: type,
      show: 'CREATE',
      data: { id, title, status, description, opportunity, dueDate },
    })
    setTaskTitle(data)
    setOpenCreateTaskModel(!openCreateTaskModel)
  }

  if (error) return <Page404 />

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Layout
            className='site-layout'
            style={{
              minHeight: '120vh',
              backgroundColor: 'rgba(255, 255, 255,1  )',
            }}
          >
            <Content
              style={{
                margin: '16px',
                overflow: 'hidden',
              }}
            >
              <div className='flex-child-element magenta top_btn'>
                {/* <Image src={NotificationIcon} /> */}
                <Button className='show-all-btn'>
                  <Link to='/showall'>Show All </Link>
                </Button>
              </div>
              <div className='flex-parent-element'>
                <div
                  className='flex-child-element magenta'
                  style={{
                    minHeight: 360,
                  }}
                >
                  <Row gutter={24}>
                    <Col span={12}>
                      <Card
                        extra={
                          <div className='add_top_btn'>
                            <PlusCircleOutlined
                              onClick={(e) => openTaskModel(data, 'Task')}
                              className='add-icon'
                            />
                          </div>
                        }
                        title={<div className='add_top_btn'>Today's Task</div>}
                        className='flex-parent-element border_less_card'
                        bordered={false}
                        headStyle={{ border: 'none' }}
                      >
                        <Row gutter={24}>
                          {data?.getDashBoardDetails?.card
                            ?.filter(
                              (item) =>
                                moment(item.dueDate).format('YYYY-MM-DD') ===
                                  dateIn && item.type === 'Task'
                            )
                            .map((card) => {
                              return (
                                <Col span={12}>
                                  <Cards {...card} />
                                </Col>
                              )
                            })}
                        </Row>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card
                        extra={
                          <div className='add_top_btn'>
                            <PlusCircleOutlined
                              className='add-icon'
                              onClick={(e) => openTaskModel(data, 'Lead')}
                            />
                          </div>
                        }
                        title={<div className='add_top_btn'>Leads</div>}
                        className='flex-parent-element border_less_card'
                        bordered={false}
                        headStyle={{ border: 'none' }}
                        style={{ cursor: 'pointer' }}
                      >
                        <Row gutter={24}>
                          {data?.getDashBoardDetails?.card
                            ?.filter((item) => item.type === 'Lead')
                            .map((card) => {
                              return (
                                <Col span={12}>
                                  <Cards {...card} />
                                </Col>
                              )
                            })}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Card
                        extra={
                          <div className='add_top_btn'>
                            <PlusCircleOutlined
                              className='add-icon'
                              onClick={(e) => openTaskModel(data, 'Prospect')}
                            />
                          </div>
                        }
                        title={<div className='add_top_btn'>Prospects</div>}
                        className='flex-parent-element prospect_card'
                        bordered={false}
                        headStyle={{ border: 'none' }}
                      >
                        <Row>
                          {data?.getDashBoardDetails?.card
                            ?.filter((item) => item.type === 'Prospect')
                            .map((card) => {
                              return (
                                <Col span={12}>
                                  <Cards {...card} />
                                </Col>
                              )
                            })}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </Content>
          </Layout>
        </div>
      )}

      {!!openCreateModel && (
        <CreateModel openModel={openModel} cardType={cardType} />
      )}

      {!!openCreateTaskModel && (
        <CreateTaskModel
          openCreateTaskModel={openCreateTaskModel}
          title={taskTitle}
          openTaskModel={openTaskModel}
          cardType={cardType}
        />
      )}
    </>
  )
}

export default Dashboard
