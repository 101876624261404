import { gql } from '@apollo/client'

export const GET_ALL_CONFIGURATION = gql`
  query getAllConfiguration {
    getAllConfiguration {
      userId
      title
      description
      daysAfter
      cardId
      daysAfterDate
      id
    }
  }
`
