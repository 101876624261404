import { Link, useNavigate } from 'react-router-dom'
import { Form, Input, Button, Typography, Space, Card } from 'antd'
import toast from 'modules/utils/toast'
import client from 'apollo'
import { LOGIN_GET_TOKEN } from '../graphql/Mutations'

const FormItem = Form.Item
const Login = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const handleSubmit = (values) => {
    client
      .mutate({ mutation: LOGIN_GET_TOKEN, variables: { input: values } })
      .then((res) => {
        localStorage.setItem('token', res.data.login.token)
        navigate('/')
        toast('success', 'User Logged In Successfully')
      })
      .catch((error) => toast('error', error.message))
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div>
          <Space
            direction='horizontal'
            style={{ width: '100%', justifyContent: 'center' }}
          >
            <Typography.Title level={1}>Welcome Back</Typography.Title>
          </Space>
          <Space
            direction='horizontal'
            style={{ width: '100%', justifyContent: 'center' }}
          >
            <Typography.Title level={5}>Happy Tasking</Typography.Title>
          </Space>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 100,
            }}
          >
            <Card
              bordered={false}
              style={{
                width: 620,
                textAlign: 'center',
              }}
            >
              <Form form={form} onFinish={handleSubmit}>
                <FormItem
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your valid username!',
                    },
                  ]}
                >
                  <Input
                    type='text'
                    placeholder='Email'
                    className='login_input'
                    style={{
                      border: 0,
                      borderBottom: '1px solid rgba(125, 161, 255, 1)',
                      borderRadius: 0,
                    }}
                  />
                </FormItem>
                <FormItem
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password!',
                      min: 2,
                      max: 16,
                    },
                  ]}
                >
                  <Input
                    type='password'
                    placeholder='Password'
                    className='login_password_input'
                    style={{
                      border: 0,
                      borderBottom: '1px solid rgba(125, 161, 255, 1)',
                      borderRadius: 0,
                    }}
                  />
                </FormItem>
                <span className='forgot-password' style={{ paddingLeft: 450 }}>
                  <Link
                    to={`/forgot-password?name=`}
                    style={{ color: '#1414C0' }}
                  >
                    Forgot Password?
                  </Link>
                </span>
                <br />
                <br />

                <FormItem>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: 200,
                      marginBottom: 100,
                    }}
                  >
                    <Button
                      type='submit'
                      className='gx-mb-0'
                      htmlType='submit'
                      style={{
                        paddingLeft: 64,
                        paddingRight: 64,
                        backgroundColor: '#1414C0',
                        color: 'white',
                        borderRadius: '15px',
                        height: '50px',
                      }}
                    >
                      Log In
                    </Button>
                  </div>
                </FormItem>
              </Form>

              <span>
                Don't have an account?
                <Link to='signup' style={{ color: '#1414C0' }}>
                  {' '}
                  Sign Up
                </Link>
              </span>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}
export default Login
