import { Link, useNavigate } from 'react-router-dom'
import {
  Form,
  Input,
  Button,
  Typography,
  Space,
  Card,
  Row,
  Select,
  Col,
} from 'antd'
import { GET_ORGANIZATION } from 'modules/Auth/Signup/graphql/Queries'
import { useQuery } from '@apollo/client'
import client from 'apollo'
import { SIGN_UP } from './graphql/Mutations'
import toast from 'utils/toast'
import { useState } from 'react'

const FormItem = Form.Item
const Signup = () => {
  const [organizationInput, setOrganizationInput] = useState(true)
  const setSelect = () => {
    setOrganizationInput(true)
  }
  const setTextfield = () => {
    setOrganizationInput(false)
  }
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const handleSubmit = (values) => {}

  const { data } = useQuery(GET_ORGANIZATION, {
    fetchPolicy: 'network-only',
  })

  const handleSignup = (values) => {
    form.validateFields().then((values) => {
      let data = {
        password: values.password,
        lastName: values.lastName,
        organization: (values?.organization).toString(),
        firstName: values.firstName,
        email: values.email,
        country: 'India',
      }

      client
        .mutate({
          mutation: SIGN_UP,
          variables: { input: data },
        })
        .then((res) => {
          toast('success', 'New user created, please login')
          navigate('/')
        })
        .catch((err) => toast('error', err.message))
    })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div>
          <Space
            direction='horizontal'
            style={{ width: '100%', justifyContent: 'center' }}
          >
            <Typography.Title level={1}>Get Started</Typography.Title>
          </Space>
          <Space
            direction='horizontal'
            style={{ width: '100%', justifyContent: 'center' }}
          >
            <Typography.Title level={5}>
              Create your account now
            </Typography.Title>
          </Space>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 50,
            }}
          >
            <Card
              bordered={false}
              style={{
                width: 620,
                textAlign: 'center',
              }}
            >
              <Form form={form} onFinish={handleSubmit}>
                <Row gutter={24} style={{ width: '100%' }}>
                  <Col span={8}>
                    <FormItem
                      name='firstName'
                      rules={[
                        {
                          required: true,
                          message: 'Please input first name!',
                        },
                      ]}
                    >
                      <Input
                        type='text'
                        placeholder='First Name'
                        style={{
                          width: '130%',
                          height: 40,
                          outline: 0,
                          border: 0,
                          borderBottom: 1,
                          borderBottomStyle: 'solid',
                          borderBottomColor: ' rgba(125, 161, 255, 1)',
                          background: 'transparent',
                          borderRadius: '0px',
                          display: 'flex',
                        }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}></Col>
                  <Col span={8}>
                    <FormItem
                      name='lastName'
                      rules={[
                        {
                          required: true,
                          message: 'Please input last name!',
                        },
                      ]}
                    >
                      <Input
                        type='text'
                        placeholder='Last Name'
                        style={{
                          width: '130%',
                          height: 40,
                          outline: 0,
                          border: 0,
                          borderBottom: 1,
                          borderBottomStyle: 'solid',
                          display: 'flex',
                          borderBottomColor: ' rgba(125, 161, 255, 1)',
                          background: 'transparent',
                          borderRadius: '0px',
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <FormItem
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please input a valid Email!',
                      type: 'email',
                    },
                  ]}
                >
                  <Input
                    type='text'
                    placeholder='Email'
                    style={{
                      width: '100%',
                      height: 40,
                      outline: 0,
                      border: 0,
                      borderBottom: 1,
                      borderBottomStyle: 'solid',

                      borderBottomColor: ' rgba(125, 161, 255, 1)',
                      background: 'transparent',
                      borderRadius: '0px',
                    }}
                  ></Input>
                </FormItem>
                <FormItem
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'password must have at least 7 characters',
                      min: 7,
                      max: 16,
                    },
                  ]}
                >
                  <Input.Password
                    bordered={false}
                    type='password'
                    placeholder='Password'
                    style={{
                      width: '100%',
                      height: 41,
                      padding: '4px 11px',
                      borderBottom: '1px solid  rgba(125, 161, 255, 1)',
                      background: 'transparent',
                      borderRadius: '0px',
                    }}
                  />
                </FormItem>

                <FormItem
                  name='confirm'
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!'
                          )
                        )
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    bordered={false}
                    type='password'
                    placeholder='Confirm Password'
                    style={{
                      width: '100%',
                      height: 41,
                      padding: '4px 11px',
                      borderBottom: '1px solid  rgba(125, 161, 255, 1)',
                      background: 'transparent',
                      borderRadius: '0px',
                    }}
                  />
                </FormItem>
                <FormItem>
                  <Space
                    direction='horizontal'
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      style={{
                        width: '100%',
                      }}
                      block
                      onClick={setSelect}
                    >
                      Find your Org
                    </Button>
                    <Button
                      style={{
                        height: '50px',
                        width: '100%',
                        color: 'black',
                        borderRadius: '15px',
                        border: '2px solid #9896FF',
                      }}
                      block
                      onClick={setTextfield}
                    >
                      Add your Org
                    </Button>
                  </Space>
                </FormItem>
                <FormItem
                  name='organization'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Organization name!',
                    },
                  ]}
                >
                  {organizationInput ? (
                    <Select
                      style={{
                        width: '100%',
                        height: 40,
                        outline: 0,
                        border: 0,
                        borderBottom: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: ' rgba(125, 161, 255, 1)',
                        background: 'transparent',
                        padding: 10,
                      }}
                      bordered={false}
                      placeholder='Organization'
                    >
                      {data &&
                        data?.getAllOrganization?.map((item) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  ) : (
                    <Input
                      type='text'
                      placeholder='Organization'
                      style={{
                        width: '100%',
                        height: 40,
                        outline: 0,
                        border: 0,
                        borderBottom: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: ' rgba(125, 161, 255, 1)',
                        background: 'transparent',
                        borderRadius: '0px',
                        display: 'flex',
                      }}
                    />
                  )}
                </FormItem>
                <FormItem>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: 200,
                      marginBottom: 100,
                    }}
                  >
                    <Button
                      type='submit'
                      className='gx-mb-0'
                      htmlType='submit'
                      onClick={handleSignup}
                      style={{
                        paddingLeft: 64,
                        paddingRight: 64,
                        backgroundColor: '#1414C0',
                        color: 'white',
                        borderRadius: '15px',
                        height: '50px',
                      }}
                    >
                      Sign Up
                    </Button>
                  </div>
                </FormItem>
              </Form>
              <span>
                Already have an account?
                <Link to='/' style={{ color: '#1414C0' }}>
                  {' '}
                  Log In
                </Link>
              </span>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}
export default Signup
