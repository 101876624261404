// import { BellOutlined } from '@ant-design/icons'
import { Badge, Image, Popover } from 'antd'
import { useState } from 'react'
import { GET_ALL_NOTIFICATION } from '../graphql/Queries'
import { useQuery } from '@apollo/client'
import NotificationIcon from '../../../assets/images/notification-icon.png'
import NotificationCard from 'componant/Notification'

const Notification = () => {
  const [visible, setVisible] = useState(false)
  const onVisibleChange = () => {
    if (visible) setVisible(false)
  }

  const { data } = useQuery(GET_ALL_NOTIFICATION, {
    fetchPolicy: 'network-only',
  })

  return (
    <>
      <Popover
        content={
          <>
            <NotificationCard data={data} />
          </>
        }
        title='Notifications'
        trigger='click'
        visible={visible}
        onVisibleChange={onVisibleChange}
        placement='bottomRight'
      >
        <Badge count={data?.getAllNotification?.count}>
          <Image
            src={NotificationIcon}
            preview={false}
            onClick={() => setVisible((prevState) => !prevState)}
            style={{ cursor: 'pointer' }}
          />
        </Badge>
      </Popover>
    </>
  )
}

export default Notification
