import { Button, Card, Col, Layout, Row } from 'antd'
import './index.css'
import Cards from 'componant/Cards/Cards'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_CARDS } from './graphql/Queries'
import CreateTaskModel from 'componant/pop-ups/CreateTaskModel'
import Loader from 'componant/Loader/Loader'
import Page404 from 'componant/Page404'

const { Content } = Layout

const Dashboard = () => {
  const [openCreateModel, setOpenCreateModel] = useState(undefined)
  const [cardType, setCardType] = useState({ type: '', show: '', data: {} })

  const { data, loading, error } = useQuery(GET_CARDS, {
    fetchPolicy: 'network-only',
  })

  const openModel = (e, type) => {
    setCardType({ type: type, show: 'CREATE' })
    setOpenCreateModel(!openCreateModel)
  }
  if (error) return <Page404 />

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Layout
            className='site-layout'
            style={{
              minHeight: '120vh',
              backgroundColor: 'rgba(255, 255, 255,1  )',
            }}
          >
            <Content
              style={{
                margin: '16px',
                overflow: 'hidden',
              }}
            >
              <div className='flex-child-element magenta top_btn'>
                <Button type='primary' ghost>
                  <Link to='/dashboard'>Recent </Link>
                </Button>
              </div>
              <div className='flex-parent-element'>
                <div
                  className='flex-child-element magenta'
                  style={{
                    minHeight: 360,
                  }}
                >
                  <Row gutter={24}>
                    <Col span={12}>
                      <Card
                        extra={
                          <div className='add_top_btn'>
                            <PlusCircleOutlined
                              onClick={(e) => openModel(e, '')}
                            />
                          </div>
                        }
                        title={<div className='add_top_btn'>All Tasks</div>}
                        className='flex-parent-element border_less_card'
                        bordered={false}
                        headStyle={{ border: 'none' }}
                      >
                        <Row gutter={24}>
                          {data?.getDashBoardDetails?.allCards
                            ?.filter((item) => item?.type === 'Task')
                            .map((card) => {
                              return (
                                <Col span={12}>
                                  <Cards {...card} />
                                </Col>
                              )
                            })}
                        </Row>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card
                        extra={
                          <div className='add_top_btn'>
                            <PlusCircleOutlined
                              onClick={(e) => openModel(e, 'Lead')}
                            />
                          </div>
                        }
                        title={<div className='add_top_btn'>All Leads</div>}
                        className='flex-parent-element border_less_card'
                        bordered={false}
                        headStyle={{ border: 'none' }}
                      >
                        <Row gutter={24}>
                          {data?.getDashBoardDetails?.allCards
                            ?.filter((item) => item.type === 'Lead')
                            .map((card) => {
                              return (
                                <Col span={12}>
                                  <Cards {...card} />
                                </Col>
                              )
                            })}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Card
                        extra={
                          <div className='add_top_btn'>
                            <PlusCircleOutlined
                              onClick={(e) => openModel(e, 'Prospect')}
                            />
                          </div>
                        }
                        title={<div className='add_top_btn'>All Prospects</div>}
                        className='flex-parent-element prospect_card'
                        bordered={false}
                        headStyle={{ border: 'none' }}
                      >
                        <Row>
                          {data?.getDashBoardDetails?.allCards
                            ?.filter((item) => item.type === 'Prospect')
                            .map((card) => {
                              return (
                                <Col span={12}>
                                  <Cards {...card} />
                                </Col>
                              )
                            })}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </Content>
          </Layout>
        </div>
      )}

      {!!openCreateModel && (
        <CreateTaskModel openTaskModel={openModel} cardType={cardType} />
      )}
    </>
  )
}

export default Dashboard
