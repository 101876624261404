import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import client from 'apollo'
import { AppContextProvider } from 'AppContext'
import App from 'App'

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
