import { createClient } from 'graphql-ws'
import { ApolloClient } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { InMemoryCache } from '@apollo/client/cache'
import { HttpLink, ApolloLink, from } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'

export const cacheData = new InMemoryCache()

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (Array.isArray(path) && path[0] === 'currentUser') {
        window.location.href = '/logout'
      }
      // const newMessage =
      //   message === 'jwt expired' ? 'Please login again!' : message
      // toast('error', newMessage)

      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
  credentials: 'include',
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const authorizationToken = localStorage.getItem('token')
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: authorizationToken ? `Bearer ${authorizationToken}` : null,
    },
  }))

  return forward(operation)
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_SERVER_SOCKET_URL,
  })
)

window.addEventListener('beforeunload', () => {
  // @ts-ignore - the function is private in typescript
  wsLink.subscriptionClient.close()
})

const client = new ApolloClient({
  cache: cacheData,
  link: from([errorLink, authMiddleware, httpLink]),
})

export default client
