import Login from 'modules/Auth/Login'
import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

export default function AuthenticRoutes() {
  const navigate = useNavigate()

  const auth = localStorage.getItem('token')
  useEffect(() => {
    if (auth) {
      navigate('/dashboard')
    }
  }, [auth, navigate])

  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
      </Routes>
    </>
  )
}
