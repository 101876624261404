import { gql } from '@apollo/client'

export const CREATE_CARD = gql`
  mutation createCard($input: cardInput) {
    createCard(input: $input) {
      type
      title
      status
      id
      orgId
      opportunity
      assignee
      createdAt
      createdBy
      description
      dueDate
    }
  }
`

export const DELETE_CARD = gql`
  mutation DeleteCard($input: deleteCardInput) {
    deleteCard(input: $input) {
      type
      title
      status
      orgId
      reminder {
        userId
        reminderTitle

        cardId
      }
      opportunity
      id
      dueDate
      description
      createdBy
      createdAt
      closedAt
      assignee
    }
  }
`

export const UPDATE_CARD = gql`
  mutation updateCard($input: updateCardInput, $where: updateWhereInput) {
    updateCard(input: $input, where: $where) {
      type
      title
      status
      opportunity
      id
      dueDate
      description
      createdBy
      createdAt
      closedAt
      assignee
    }
  }
`

export const CREATE_COMMENT = gql`
  mutation createComment($input: commentInput) {
    createComment(input: $input) {
      comment
      createdAt
    }
  }
`

export const DELETE_REMINDER = gql`
  mutation deleteReminder($input: deleteInput) {
    deleteReminder(input: $input) {
      userId
      reminderTitle
      createdAt
      cardId
      card {
        title
      }
    }
  }
`

export const CREATE_REMINDER = gql`
  mutation createReminder($input: reminderInput) {
    createReminder(input: $input) {
      cardId
    }
  }
`

export const CREATE_CONFIGURATION = gql`
  mutation CreateConfiguration($input: configurationInput) {
    createConfiguration(input: $input) {
      cardId
      daysAfter
      description
      daysAfterDate
      id
      title
      userId
    }
  }
`
export const DELETE_CONFIGURATION = gql`
  mutation DeleteConfiguration($input: deleteAndUpdateInput) {
    deleteConfiguration(input: $input) {
      id
      title
      description
      daysAfter
      daysAfterDate
      userId
      cardId
    }
  }
`
