import { gql } from '@apollo/client'

export const SIGN_UP = gql`
  mutation signUp($input: userInput) {
    signUp(input: $input) {
      lastName
      firstName
      email
      country
      card {
        title
        status
        type
        opportunity
        dueDate
        description
        createdBy
        createdAt
        closedAt
        assignee
        orgId
      }
      nickNameColor
      nickName
      organization {
        name
      }
      role
    }
  }
`
