import { Card, Col, Image, Row, Tag } from 'antd'
import './index.css'
import { useState } from 'react'
import CreateModel from 'componant/pop-ups/CreateModel'
import moment from 'moment'

import dollarIcon from '../../assets/images/noto_money-bag.png'

const Cards = ({
  id,
  title,
  status,
  description,
  opportunity,
  dueDate,
  assignee,
  type,
}) => {
  const [openCreateModel, setOpenCreateModel] = useState(undefined)
  const [cardType, setCardType] = useState({ type: '', show: '', data: {} })

  const openModel = () => {
    setCardType({
      type: '',
      show: 'READ',
      data: { id, title, status, description, opportunity, dueDate, assignee },
    })
    setOpenCreateModel(!openCreateModel)
  }
  return (
    <>
      <Card
        onClick={openModel}
        title={<span className='card_title'>{title}</span>}
        headStyle={{ border: 'none', fontSize: '12px', cursor: 'pointer' }}
        className='main_card'
      >
        <div>
          <span className='card_description'>{description}</span>
        </div>
        <div className='content_row'>
          <Row gutter={24} justify={'space-between'}>
            <Col span={8}>
              <div className='status_tag' style={{ textAlign: 'start' }}>
                <Tag color='#FFFF00'>{status}</Tag>
              </div>
            </Col>
            <Col span={8}>
              <div className='status_tag' style={{ textAlign: 'center' }}>
                <span>{moment(dueDate).format('DD-MM-YYYY')}</span>
              </div>
            </Col>
            {type !== 'Task' && (
              <Col span={8}>
                <div className='status_tag' style={{ textAlign: 'end' }}>
                  <Image
                    src={dollarIcon}
                    alt='dollarIcon'
                    preview={false}
                    style={{ height: '16px' }}
                  />
                  <span style={{ marginLeft: '10px' }}>{opportunity}</span>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Card>
      {!!openCreateModel && (
        <CreateModel openModel={openModel} cardType={cardType} />
      )}
    </>
  )
}

export default Cards
