import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Modal from 'antd/lib/modal/Modal'
import React, { useEffect, useState } from 'react'
import CalenderIcon from '../../assets/images/calendar-icon.png'
import moment from 'moment'
import client from 'apollo'
import { CREATE_CARD } from 'componant/graphql/Mutations'
import { GET_CARDS } from 'modules/Dashboard/graphql/Queries'
import toast from 'utils/toast'
import { GET_ORGANIZATION_USERS } from 'componant/graphql/Queries'
import { useQuery } from '@apollo/client'

import './index.css'

const { Option } = Select
const FormItem = Form.Item

const CreateTaskModel = ({ openTaskModel, cardType }) => {
  const [form] = Form.useForm()

  const [status, setStatus] = useState('')
  const [assignee, setAssignee] = useState([])
  const [date, setDate] = useState()
  const [createTaskLoading, setCreateTaskLoading] = useState(false)
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [assignUser, setAssignUser] = useState([])

  const { data: organizationUser, loading } = useQuery(GET_ORGANIZATION_USERS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    // if (assignee.length > 0) {
    setAssignUser(
      organizationUser?.getOrganizationUsers?.filter((item) =>
        assignee?.includes(item?.id)
      )
    )
    // }
  }, [assignee, organizationUser?.getOrganizationUsers])

  const handleOk = (values) => {
    setCreateTaskLoading(true)
    let data = {
      title: values?.title,
      description: values?.description,
      status: status,
      opportunity: parseFloat(values?.opportunity),
      dueDate: date,
      assignee: assignee,
      type: cardType?.type,
    }

    client
      .mutate({
        mutation: CREATE_CARD,
        variables: { input: data },
        refetchQueries: [
          {
            query: GET_CARDS,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setCreateTaskLoading(false)
        openTaskModel()
        toast('success', 'Card successfully created')
      })
      .catch((err) => {
        toast('error', 'Something went wrong!')
        console.log(err)
      })
      .finally(() => setCreateTaskLoading(false))
  }

  const handleStatusSelect = (e) => {
    setStatus(e)
  }
  const handleAssigneeSelect = (e) => {
    setAssignee(e)
  }
  const handleDate = (e) => {
    setDate(moment(e).format('YYYY-MM-DD'))
    setOpenDatePicker(false)
  }

  return (
    <Modal
      visible={true}
      onCancel={openTaskModel}
      footer={null}
      width={1200}
      height={844}
      className='main_model'
    >
      <Form form={form} onFinish={handleOk}>
        <Row gutter={16}>
          <Col span={7}>
            <FormItem
              name='title'
              rules={[
                {
                  required: true,
                  message: 'Please input your Task Title!',
                },
              ]}
            >
              <Input placeholder='Title' className='title-task-field' />
            </FormItem>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <FormItem
              name='opportunity'
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^[-+]?[0-9]+$/),
                  message: 'Please input Valid Opportunity!',
                },
              ]}
            >
              <Input
                placeholder='$ amount in numbers'
                className='title-task-field'
              />
            </FormItem>
          </Col>
          <Col span={1}></Col>
          <Col span={3}>
            <FormItem
              name='status'
              rules={[
                {
                  required: true,
                  message: 'Please Select Status!',
                },
              ]}
            >
              <Select
                className='status-btn'
                placeholder='status'
                onChange={handleStatusSelect}
                bordered={false}
              >
                <option label='Pending' value='Pending'>
                  Pending
                </option>
                <option label='Initiated' value='Initiated'>
                  Initiated
                </option>
                <option label='Closed' value='Closed'>
                  Closed
                </option>
              </Select>
            </FormItem>
          </Col>
          <Col span={6} style={{ display: 'flex' }}>
            <div className='select_assignee'>
              <div
                className={assignUser && 'remove_textBox'}
                style={{ display: 'block' }}
              >
                <FormItem
                  style={{ marginLeft: '25px', marginBottom: '0' }}
                  name='assignee'
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Assignee!',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder='assignee'
                    mode='multiple'
                    onChange={handleAssigneeSelect}
                    bordered={false}
                    className='assignee_btn'
                  >
                    {!loading &&
                      organizationUser?.getOrganizationUsers?.map((option) => (
                        <Option
                          value={option.id}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {`${option.firstName} ${option.lastName}`}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
                <Avatar.Group style={{ marginBottom: '10px' }}>
                  {assignUser?.map((user) => {
                    return (
                      <Avatar
                        size='small'
                        icon={user?.nickName}
                        style={{ background: user?.nickNameColor }}
                      />
                    )
                  })}
                </Avatar.Group>
              </div>
              {/* )} */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              name='description'
              rules={[
                {
                  required: true,
                  message: 'Please input Description!',
                },
              ]}
            >
              <TextArea
                className='text-area-field'
                placeholder='Description'
                rows={4}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <div
              className='reminder-image'
              style={{ display: 'flex', justifyContent: 'space-evenly' }}
            >
              <Image
                style={{ marginTop: '8px', cursor: 'pointer' }}
                src={CalenderIcon}
                alt='user image'
                preview={false}
                onClick={() => setOpenDatePicker(!openDatePicker)}
              />
              {openDatePicker && (
                <FormItem
                  name='dueDate'
                  rules={[
                    {
                      required: true,
                      message: 'Please Select dueDate!',
                    },
                  ]}
                >
                  <DatePicker
                    open={openDatePicker}
                    style={{ width: '150px' }}
                    className='status-btn'
                    placeholder='Select Due Date'
                    format={'YYYY-MM-DD'}
                    onChange={handleDate}
                    disabledDate={(current) =>
                      current.isBefore(moment().format())
                    }
                  />
                </FormItem>
              )}
              {date && !openDatePicker && (
                <div style={{ marginTop: '10px' }}>
                  <span>{date}</span>
                </div>
              )}
            </div>
          </Col>
          <Col span={15}></Col>
          <Col span={2}>
            <div>
              <FormItem>
                <Button
                  style={{ width: '150px' }}
                  size='large'
                  className='create-task-btn'
                  type='submit'
                  htmlType='submit'
                  loading={createTaskLoading}
                >
                  Create
                </Button>
              </FormItem>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default CreateTaskModel
