import { Input, Space, Tag, Tooltip } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'

const Tags = ({ data }) => {
  const [tags, setTags] = useState(data)
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const inputRef = useRef(null)
  const editInputRef = useRef(null)

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])
  useEffect(() => {
    editInputRef.current?.focus()
  }, [inputValue])

  const showInput = () => {
    setInputVisible(true)
  }
  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue])
    }
    setInputVisible(false)
    setInputValue('')
  }

  const tagInputStyle = {
    width: 78,
    verticalAlign: 'top',
  }
  return (
    <Space size={[0, 8]} wrap>
      <Space size={[0, 8]} wrap>
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20
          const tagElem = (
            <Tag
              key={tag}
              style={{
                userSelect: 'none',
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          )
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          )
        })}
      </Space>
      {inputVisible ? (
        <Input
          ref={inputRef}
          type='text'
          size='small'
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <PlusCircleOutlined
          style={{ fontSize: '20px', color: 'blue' }}
          onClick={showInput}
        />
      )}
    </Space>
  )
}

export default Tags
