import { Avatar, Button, Col, Divider, List } from 'antd'
import client from 'apollo'
import { READ_ALL_NOTIFICATION } from 'modules/Auth/graphql/Mutations'
import { GET_ALL_NOTIFICATION } from 'modules/Auth/graphql/Queries'
import React from 'react'

const NotificationCard = (data, setVisible) => {
  const notificationArray = data?.data?.getAllNotification?.notifications

  const handleClick = () => {
    client
      .mutate({
        mutation: READ_ALL_NOTIFICATION,
        refetchQueries: [
          {
            query: GET_ALL_NOTIFICATION,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setVisible(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      {notificationArray?.length ? (
        <div>
          <div style={{ maxHeight: '400px', overflow: 'auto' }}>
            {notificationArray
              ?.filter((data) => !data.isRead)
              ?.map((item) => {
                return (
                  <>
                    <div
                      style={{
                        width: '500px',
                      }}
                    >
                      <List>
                        <Col span={24}>
                          <div style={{ display: 'flex' }}>
                            <Avatar
                              size='small'
                              icon={item?.title?.split(' ')[0]}
                              style={{
                                background: item?.title?.split(' ')[1],
                              }}
                            />
                            <p style={{ fontSize: '16px', marginLeft: '10px' }}>
                              {item?.title?.split(' ')?.slice(2)?.join(' ')}{' '}
                            </p>
                          </div>
                          <p> {item.description}</p>
                        </Col>
                      </List>
                    </div>
                    <Divider orientation='left' />
                  </>
                )
              })}
          </div>
          <Button onClick={handleClick}>Mark as read</Button>
        </div>
      ) : (
        <List>
          <p style={{ fontSize: '16px' }}>There are no new notification </p>
        </List>
      )}
    </>
  )
}

export default NotificationCard
