import { Avatar, Layout, Popover } from 'antd'
import React, { Fragment, useState } from 'react'
import {
  MenuOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
} from '@ant-design/icons'
import { logout } from 'modules/utils/auth'
import { useContext } from 'react'
import { AppContext } from 'AppContext'
import Notification from './Notification'

const { Header } = Layout

const Headers = ({ collapsed, toggle, drawerToggle }) => {
  const [visibleMenu, setVisibleMenu] = useState(false)

  const userMenuOptions = (
    <ul className='gx-user-popover'>
      <li onClick={logout}>
        <PoweroffOutlined /> Logout
      </li>
    </ul>
  )

  const {
    state: { currentUser },
  } = useContext(AppContext)

  return (
    <Fragment>
      <Header className='site-layout-background'>
        {React.createElement(
          window.innerWidth >= 991
            ? collapsed
              ? MenuUnfoldOutlined
              : MenuFoldOutlined
            : MenuOutlined,
          {
            className: 'trigger',
            onClick: () =>
              window.innerWidth >= 991 ? toggle() : drawerToggle(),
          }
        )}

        <div className='gx-flex-row gx-align-items-center gx-avatar-row'>
          <Notification />

          <Popover
            placement='bottomRight'
            onVisibleChange={() => setVisibleMenu(!visibleMenu)}
            open={visibleMenu}
            content={userMenuOptions}
            trigger='click'
          >
            <div>
              <Avatar
                style={{
                  backgroundColor: currentUser.nickNameColor,
                  verticalAlign: 'middle',
                }}
                size='default'
                className='gx-pointer'
              >
                {currentUser.nickName}
              </Avatar>
              <span className='gx-avatar-name'>
                <span
                  className={`header-name-ml header-name`}
                  style={{ textTransform: 'capitalize' }}
                >
                  {`${currentUser.firstName} ${currentUser.lastName}`}
                </span>
                <i className='icon icon-chevron-down gx-fs-xxs gx-ml-2' />
              </span>
            </div>
          </Popover>
        </div>
      </Header>
    </Fragment>
  )
}
export default Headers
