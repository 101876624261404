import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      role
      firstName
      lastName
      email
      organization {
        name
      }
      nickName
      nickNameColor
      card {
        reminderDate
      }
    }
  }
`

export const GET_ALL_NOTIFICATION = gql`
  query getAllNotification {
    getAllNotification {
      notifications {
        title
        receiverId
        link
        isRead
        description
        createdAt
      }
      count
    }
  }
`
