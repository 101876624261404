import { Layout, Space } from 'antd'
import React, { useState } from 'react'
import './index.css'
import ConfigurationFollowUp from './ConfigurationFollowUp'
import ConfigurationStatus from './ConfigurationStatus'

const { Content } = Layout

const Config = () => {
  // const [tabMenu, setTabMenu] = useState('')
  const [organizationInput, setOrganizationInput] = useState(true)

  return (
    <>
      <Layout
        className='site-layout'
        style={{
          minHeight: '120vh',
          backgroundColor: 'rgba(255, 255, 255,1  )',
        }}
      >
        <Content
          style={{
            margin: '16px',
            overflow: 'hidden',
          }}
        >
          <div className='tabs-tabs'>
            <Space
              direction='horizontal'
              style={{
                width: '100%',
                marginLeft: '10px',
              }}
            >
              <div className='tabs_main_title'>
                <div
                  className={`tab_follow_up_title ${
                    organizationInput && 'active'
                  }`}
                >
                  <span
                    className='tab_follow_up'
                    onClick={() => setOrganizationInput(true)}
                  >
                    Follow Up
                  </span>
                </div>
                <div className={!organizationInput && 'active'}>
                  <span
                    className='tab_status'
                    onClick={() => setOrganizationInput(false)}
                  >
                    Status
                  </span>
                </div>
              </div>
            </Space>
            {organizationInput ? (
              <ConfigurationFollowUp />
            ) : (
              <ConfigurationStatus />
            )}
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default Config
