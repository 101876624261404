import { gql } from '@apollo/client'

export const LOGIN_GET_TOKEN = gql`
  mutation login($input: loginInput) {
    login(input: $input) {
      token
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($input: userUpdateInput) {
    updateUser(input: $input) {
      firstName
      lastName
      role
      password
      nickNameColor
      nickName
      organization {
        name
      }
      email
      country
    }
  }
`

export const READ_ALL_NOTIFICATION = gql`
  mutation ReadAllNotification {
    readAllNotification {
      isRead
      createdAt
      description
      link
      receiverId
      title
    }
  }
`
