import React, { useState } from 'react'
import { Drawer, Layout } from 'antd'
import { NavLink } from 'react-router-dom'
import Headers from './Header'
import SideBar from './Sider'
import ContentRoute from 'modules/Organization/Routes/ContentRoute'
import SideMenuBar from './SideMenuBar'
import LogoWhite from '../../../assets/images/headerLogo.png'
import './layout.css'

const { Content } = Layout

export default function Dashboard() {
  const [collapsed, setCollapsed] = useState(false)
  const [visibleDrawer, setVisibleDrawer] = useState(false)

  return (
    <>
      <Layout>
        <SideBar collapsed={collapsed} />
        <Layout className={`site-layout with-alert`}>
          <Headers
            collapsed={collapsed}
            toggle={() => setCollapsed(!collapsed)}
            drawerToggle={() => setVisibleDrawer(!visibleDrawer)}
          />
          <Drawer
            placement='left'
            closable={false}
            open={visibleDrawer}
            rootClassName='layout-drawer'
            onClose={() => setVisibleDrawer(!visibleDrawer)}
            title={
              <NavLink to='/' className='nav-text'>
                <img alt='Finideas' src={LogoWhite} />
              </NavLink>
            }
          >
            <div className='menu-wrapper'>
              <SideMenuBar setVisibleDrawer={setVisibleDrawer} />
            </div>
          </Drawer>
          <Content>
            <div className={`content-wrapper admin-content-wrapper`}>
              <ContentRoute />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
