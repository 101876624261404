import { Card } from 'antd'
import Tags from './Tags'

const ConfigurationStatus = () => {
  const statusData = ['To Do', 'In Process', 'Completed']
  const prospectsStatus = ['Open', 'Awaiting Response', 'Done']
  const leedsStatus = [
    'Initiated',
    'Assessment',
    'Documentation',
    'Negotiation',
  ]
  return (
    <>
      <div>
        <Card
          style={{
            width: '100%',
            border: '1px solid #AFCFFF',
            borderRadius: '15px',
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <span>Task Status</span>
          </div>
          <div>
            <Tags data={statusData} />
          </div>
        </Card>
        <Card
          style={{
            width: '100%',
            border: '1px solid #AFCFFF',
            borderRadius: '15px',
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <span>Prospects Status</span>
          </div>
          <div>
            <Tags data={prospectsStatus} />
          </div>
        </Card>
        <Card
          style={{
            width: '100%',
            border: '1px solid #AFCFFF',
            borderRadius: '15px',
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <span>Leeds Status</span>
          </div>
          <div>
            <Tags data={leedsStatus} />
          </div>
        </Card>
      </div>
    </>
  )
}
export default ConfigurationStatus
