import { gql } from '@apollo/client'

export const GET_CARDS = gql`
  query getDashBoardDetails {
    getDashBoardDetails
  }
`

export const GET_ALL_DASHBOARD_CARDS = gql`
  query getAllCardsOnDashboard {
    getAllCardsOnDashboard
  }
`

export const GET_ALL_CARDS = gql`
  query getAllCards {
    getAllCards {
      type
      title
      status
      reminder {
        reminderTitle
      }
      orgId
      opportunity
      id
      dueDate
      createdBy
      createdAt
      description
      closedAt
      assignee
    }
  }
`
