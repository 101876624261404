import { gql } from '@apollo/client'

export const CREATE_CONFIGURATION = gql`
  mutation createConfiguration($input: configurationInput) {
    createConfiguration(input: $input) {
      userId
      title
      description
      daysAfterDate
      daysAfter
      cardId
    }
  }
`

export const DELETE_CONFIGURATION = gql`
  mutation deleteConfiguration($input: deleteAndUpdateInput) {
    deleteConfiguration(input: $input) {
      userId
      title
      description
      daysAfterDate
      daysAfter
      cardId
    }
  }
`
