import AuthenticRoutes from 'modules/Organization/Routes/AuthenticRoutes'
import ProtectedRoutes from 'modules/Organization/Routes/ProtectedRoutes'
import { Route, Routes } from 'react-router-dom'

import 'assets/css/index.css'
import './assets/css/App.css'
import './assets/css/Main.css'
import './assets/css/Custom.css'
import Signup from 'modules/Auth/Signup'

export default function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<AuthenticRoutes />} />
        <Route path='/*' element={<ProtectedRoutes />} />
        <Route path='/signup' element={<Signup />} />
      </Routes>
    </>
  )
}
