import Config from 'componant/Configuration/Config'
import Organization from 'modules/Organization/Organization'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../../Dashboard/Dashboard'
import ShowAllDashboard from 'modules/Dashboard/ShowAllDashboard'
import Profile from 'modules/Auth/Profile'

export default function ContentRoute() {
  return (
    <>
      <Routes>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/organizations' element={<Organization />} />
        <Route path='/configurations' element={<Config />} />
        <Route path='/showall' element={<ShowAllDashboard />} />
        <Route path='/profile' element={<Profile />} />
      </Routes>
    </>
  )
}
