import React from 'react'
import { Layout } from 'antd'

import SideMenuBar from './SideMenuBar'
import logoSrc from '../../../assets/images/headerLogo.png'
import './layout.css'
import { NavLink } from 'react-router-dom'

const { Sider } = Layout

const SideBar = ({ pathname, collapsed }) => {
  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className='logo'>
        <a
          href='https://www.finideas.com'
          target='_blank'
          className='nav-text'
          rel='noopener noreferrer'
        >
          <img alt='Finideas' src={logoSrc} />
        </a>
      </div>
      <div className='menu-wrapper'>
        <SideMenuBar pathname={pathname} />
      </div>
      <div className='profile_main_btn'>
        <div className='profile_btn'>
          <NavLink to='/profile' className='nav-text'>
            <span className='sidebar-text'>Profile</span>
          </NavLink>
        </div>
      </div>
    </Sider>
  )
}
export default SideBar
