import '../Dashboard/index.css'
import { Col, Progress, Row } from 'antd'
import { Collapse } from 'antd'
import Cards from '../../componant/Cards/Cards'
import { useContext, useState } from 'react'
import { AppContext } from 'AppContext'
import { useQuery } from '@apollo/client'
import { GET_ORGANIZATION_USERS } from 'componant/graphql/Queries'

const { Panel } = Collapse

const Organization = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext)
  const [viewCard, setViewCard] = useState(false)
  const handleClick = () => {
    setViewCard(true)
  }
  const { data, loading } = useQuery(GET_ORGANIZATION_USERS, {
    fetchPolicy: 'network-only',
  })

  return (
    <>
      <div className='wrapper'>
        <div className='card-wrapper'>
          <Collapse collapsible='header'>
            <Panel
              class='comp'
              header={
                <div style={{ textTransform: 'capitalize' }}>
                  {`${currentUser.firstName} ${currentUser.lastName}`}
                  <div style={{ fontSize: 12 }}>{currentUser.role}</div>
                </div>
              }
              key='1'
            >
              {!loading &&
                data?.getOrganizationUsers
                  ?.filter((item) => item.role !== 'Admin')
                  ?.map((option) => {
                    return (
                      <Col>
                        <div>
                          <div
                            className='task-associate-name'
                            onClick={handleClick}
                          >
                            <Row>
                              <Col span={16}>
                                <div className='assigner-name'>
                                  {option.firstName}
                                </div>
                                <div className='assigner-role'>
                                  {option.role}
                                </div>
                              </Col>
                              <Col span={8} style={{ padding: '0' }}>
                                <Progress
                                  type='circle'
                                  percent={70}
                                  width={50}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
            </Panel>
          </Collapse>
        </div>
        {viewCard && (
          <div style={{ padding: '30px' }}>
            <Row>
              <Col span={6}>
                <Cards style={{ borderRadius: '8px' }} />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  )
}

export default Organization
